<template>
  <van-popup :close-on-click-overlay="false" v-model="visibility" class="dialog-alert">
    <div class="title">
      {{ title }}
    </div>
    <div class="cont">{{ message }}</div>
    <div class="footer">
      <img
        class="submit"
        @click="close"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/suixinxiang0.01/button.png"
      />
    </div>
  </van-popup>
</template>

<script>
export default {
  props: {
    visibility: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    message: { type: String, default: "" },
  },
  methods: {
    close() {
      this.$emit("update:visibility", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-alert {
  border-radius: 18px;
  width: 658px;
  padding-bottom: 42px;
  background: url("https://cdn.qiumo.net.cn/allsmarketmaterial/suixinxiang0.01/dialog.png")
    center center/contain no-repeat;
  width: 656px;
  height: 1195px;
  padding-top: 215px;
  box-sizing: border-box;
  overflow: hidden;

  .title {
    color: #fff;
    text-align: justify;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0 0 44px 0;
    text-align: center;
  }

  .cont {
    color: #fff;
    padding: 0 21px 0 24px;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    white-space: pre-wrap;
    max-height: 748px;
    overflow: auto;
  }
  .footer {
    padding-top: 38px;
    text-align: center;
    .submit {
      width: 449px;
    }
  }
}
</style>
