import request from "@/utils/request";
const ipBaseURL = "https://test-huanxinxiang.ai-me.cn";

// 支付宝刷单
export const alipaySinglePay = (params, data) =>
  request({
    url: "/huanxinxiang-service/v1/alipay-single-pay/",
    method: "post",
    ipBaseURL,
    params,
    data,
  });

